@import "assets/styles/scss/variables";

.onboarding {
	min-height: 100vh;
	position: relative;

	.logo {
		height: 40px;
	}

	.status__bar {
		width: 240px;
		margin: 10px auto;
		font-size: 10px;
		color: $gray-fill-med;
		.circle {
			margin-top: -10px;
		}
		@media (max-width: 768px) {
			width: 200px;
		}
	}

	.small-circle {
		&.one {
			margin: 0 auto 0.5rem;
			background-color: $primary-fill;

			&::after {
				content: "1";
				display: block;
				color: #fff;
				position: absolute;
				font-weight: 600;
				left: 7px;
				top: 1px;
			}

			&.mark::after {
				content: "\2713";
				left: 4px;
				top: 1px;
			}
		}
	}

	.form-group {
		// max-width: 320px;
		margin-right: auto;
		margin-left: auto;
	}

	.onboarding__right__view {
		background-color: $primary-fill;
		background-image: url("../../assets/images/images//onboarding.png");
		background-repeat: no-repeat;
		margin-top: 0px;
	}

	.border__overhead {
		border-bottom: 2px solid $gray-fill-border;
		text-align: center;
		margin: 20px 10px 0 10px !important;

		p {
			margin-bottom: -13px;
			background-color: $pure-white;
			display: inline-flex;
			padding-left: 20px;
			padding-right: 20px;

			#{$meriNight} {
				background-color: $primary-full-deep;
				color: $pure-white !important;
			}
		}
	}

	.w-90 {
		width: 90%;
		margin: auto;
	}
	@media (max-width: 768px) {
		min-height: 93vh;
	}
}
