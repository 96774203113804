@import "./variables";

svg {
	&.primary {
		path {
			fill: $primary-fill;

			#{$meriNight} {
				fill: $white-fill;
			}
		}
	}
	&.danger {
		path {
			fill: $danger-fill;
		}
	}
	&.gray {
		path {
			fill: $gray-fill-main;
		}
	}
	&.primary-light {
		path {
			fill: $primary-light;
		}
	}
	&.white {
		path {
			fill: $white-fill;
		}
	}
}

.nm-5 {
	margin-top: -25px;
}

.border-gray {
	border: 1px solid $gray-fill-border;
}

.rot-180 {
	transform: rotate(180deg);
	transition: all 200ms linear;
}
.bg-warning,
.primary__med {
	#{$meriNight} {
		color: $text-fill !important;
	}
}
.bg-light {
	#{$meriNight} {
		background-color: $dark-header !important;
	}
}
.blue__light {
	background-color: $blue-light;
	color: $blue-fill;
	#{$meriNight} {
		color: $text-fill !important;
	}
}
.purple__light {
	background-color: $purple-light;
	color: $purple-fill;
}
.danger__light {
	background-color: $danger-light;
	color: $danger-fill;
}
.primary__light {
	background-color: $primary-light;
	color: $primary-fill;
}
.bg__gray {
	background-color: $gray-fill-light;
	#{$meriNight} {
		background-color: $dark-header;
	}
}
.primary__med {
	background-color: $primary-fill-med;
}
.text--primary--med {
	color: $primary-fill-med;
}
.green__light {
	background: $primary-btn-grad;
}
.faint__primary {
	background: $table-bg;
}

.med-circle {
	width: 30px !important;
	height: 30px !important;
}

.med-circle,
.small-circle {
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	position: relative;
}

.small-circle {
	width: 17px;
	height: 17px;

	&.smallest {
		width: 15px;
		height: 15px;
		font-size: 1rem;
	}

	&.dot {
		width: 7px !important;
		height: 7px !important;
	}

	&.normal {
		background-color: $white-fill;
		border: 2px solid $gray-fill-med;
		color: $gray-fill-med;
	}
}

.content__slider {
	overflow-x: scroll;
	overflow-y: auto !important;
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	flex-wrap: nowrap;
	box-sizing: border-box;
	-ms-overflow-style: none !important;
	scrollbar-width: none !important;

	&::-webkit-scrollbar {
		display: none !important;
	}
}

.nav-tabs {
	border-bottom: 1px solid #ccc !important;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	font-size: 12px;

	&.no-flex {
		justify-content: flex-start;
	}

	li.nav-item,
	a.nav-item {
		text-align: center;
		display: flex;
		align-items: stretch;
		justify-content: center;
	}

	.nav-link {
		border: none;
		color: $gray-fill-main;
		font-weight: 600;
		margin-bottom: 0;
		// transition: all 500ms linear;
		white-space: nowrap;
		width: 100%;
		#{$meriNight} {
			color: $gray-fill-light-new;
		}

		&.active {
			color: $primary-fill;
			border-bottom: 3px solid $primary-fill;
			margin-bottom: -1px;

			#{$meriNight} {
				background-color: unset !important;
			}
		}
	}
}

.div__wrapper {
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
	border-radius: 6px;
	overflow: hidden;
	position: relative;
	#{$meriNight} {
		box-shadow: 0px 0px 20px 0 rgba(255, 255, 255, 0.1);
	}
}

.MuiAccordionSummary-content {
	margin: 0 !important;
}

// [class^="MuiPaper-elevation"] {
// 	box-shadow: none !important;
// 	border-bottom: 1px solid $gray-fill-border;
// }
.MuiPaper-root {
	#{$meriNight} {
		color: $gray-fill-light-new;
	}
}

.MuiPaper-elevation1 {
	box-shadow: none !important;
}
.MuiAccordionDetails-root {
	display: block !important;
	padding: 10px 0 0 0 !important;
}

.MuiAccordion-root {
	background: inherit !important;
	&.Mui-expanded {
		margin: 0 !important;
	}
}

.MuiAccordion-root:before {
	background: none !important;
}

.port__summary {
	.switchers {
		display: flex;
		justify-content: flex-end;

		p {
			border-bottom: 1px solid #efefef;
			cursor: pointer;
			font-weight: 600;
			padding-right: 15px;
			padding-left: 15px;
			margin: 0;

			&.active {
				border-bottom: 4px solid #078950;
			}

			@media (max-width: 600px) {
				padding-right: 8px;
				padding-left: 8px;
			}
		}
	}
}
.chart__wrap {
	width: 100%;
	height: 400px;
	position: relative;

	.loader {
		left: 0;
		top: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.large {
		height: calc(100vh - 150px);
	}
	&.small {
		height: 50px;

		@media (max-width: 600px) {
			height: 50px;
		}
	}

	@media (max-width: 600px) {
		width: 100%;
		height: 300px;
	}
}

.wrapper--overflow {
	-ms-overflow-style: none;
	flex-wrap: nowrap;
	scrollbar-width: none;
	overflow-x: auto;
}

.wrapper--overflow::-webkit-scrollbar {
	display: none;
}

.shadow {
	#{$meriNight} {
		box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 15%) !important;
	}
}

ul.bottom__border {
	li:not(:last-child) {
		border-bottom: 1px solid $gray-fill-border;
	}
}
.tooltipContainer {
	padding: 5px 20px;
	border-radius: 8px;
	position: absolute;
	top: 30px;
	left: 0;
	text-align: left;
	background: white;

	#{$meriNight} {
		background: $primary-deep;
	}

	li {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		span {
			padding-left: 40px;
		}
	}
}
