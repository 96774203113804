.frequent__slide {
	min-width: 280px;
	width: 280px;
	margin-right: 30px;
	padding: 10px 20px;

	img {
		max-height: 30px;
	}
}
