@import "assets/styles/scss/variables";

.cards {
	padding: 15px 30px;
	background-image: url("../../../../assets/images/images/card.png");
	background-size: 100%;
	-webkit-background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	color: $white-fill !important;
	border-radius: 10px;
	margin-top: 10px;
	min-height: 80px;

	@media (max-width: 600px) {
		padding: 15px;
	}
}

.avatar {
	.form-check-input {
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 3s;
		height: 30px;
		width: 30px;
		cursor: pointer;
		border: 2px solid $primary-fill;
		margin-right: 10px;
		margin-bottom: 15px;

		&:checked {
			background-image: unset !important;
			background-color: unset !important;
			border-color: $primary-fill !important;
			outline: none !important;
			outline-width: 0 !important;
			box-shadow: none;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;

			&::after {
				content: "\2713";
				font-size: 20px;
				color: $primary-fill !important;
				font-weight: 600;
			}
		}

		&.bg-dark {
			background-color: $text-fill-main !important;
			border: 1px solid $text-fill-main !important;
			&:checked::after {
				color: $white-fill !important;
			}
		}
		&.bg__gray {
			background-color: $gray-fill-main !important;
			border: 1px solid $gray-fill-main !important;
			&:checked::after {
				color: $white-fill !important;
			}
		}
		&.bg__pink {
			background-color: $pink-fill !important;
			border: 1px solid $pink-fill !important;
			&:checked::after {
				color: $white-fill !important;
			}
		}
	}
}
