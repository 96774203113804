@import "assets/styles/scss/variables";

.bvn__info {
	max-height: 400px;
	overflow-y: scroll;

	&.doc {
		max-height: 560px;
	}

	.bvn__details {
		margin-bottom: 0;
		.form-group {
			margin-bottom: 10px;
		}
	}

	.card {
		border: 1px solid $gray-fill-light;
		#{$meriNight} {
			background: $primary-full-deep !important;
		}
		.card-body {
			padding-bottom: 0;
		}
	}

	.bvn__tab {
		position: relative;
		background-color: $gray-fill-light;
		padding: 10px;
		font-weight: 600;

		#{$meriNight} {
			background-color: $primary-deep !important;
		}

		&.open::after {
			transform: rotate(90deg);
			transition: all 0.3s linear;
		}

		&::after {
			content: "\003E";
			display: block;
			text-align: center;
			color: #000;
			position: absolute;
			right: 20px;
			top: 5px;
			font-size: 24px;
			transform: rotate(-90deg);
			transition: all 0.3s linear;
		}
	}
}

.selfieFrame {
	width: 100%;
	min-height: 580px !important;
}
.selfie__placeholder {
	height: 249px;
	width: 333px;
	background: $gray-fill-border;
	border-radius: 5px;
	overflow: hidden;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.video-frame {
	position: relative;
	margin: 0 30px;

	.custom-overlay {
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
	}

	.frame-edge {
		position: absolute;
		border-width: 8px 0 0 8px;
		border-style: solid;
		border-radius: 8px 0;
		border-color: red;
		width: 50px;
		height: 50px;
		background: none;
		border-color: $primary-fill;

		&.right {
			right: 0;
			transform: rotate(90deg);
		}
		&.bottom-left {
			bottom: 0;
			transform: rotate(270deg);
		}
		&.bottom-right {
			right: 0;
			bottom: 0;
			transform: rotate(180deg);
		}
		#{$meriNight} {
			border-color: $white-fill !important;
		}
	}
	.video-frame-circle,
	video {
		width: 200px;
		height: 250px;
		border-radius: 50%;
		background: rgb(255, 239, 193);
		margin: auto;
		border: 2px solid $primary-fill;

		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);

		overflow: hidden;
		object-fit: cover;
	}

	.countdown {
		font-weight: 700;
		font-size: 128px;
		line-height: 159px;
		text-align: center;
		color: $white-fill;
	}
}
.selfie-modal-back-icon {
	position: absolute;
	left: 20px;
	top: 20px;
}
