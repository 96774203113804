.alloc {
	&.invest__cat {
		min-width: 230px !important;
		width: 20px !important;
	}
}
.sectors {
	color: #fff;
	.img-cover {
		width: 60px;
		height: 60px;
		background: rgba(255, 255, 255, 0.15);

		svg {
			max-height: 40px;
			height: 40px;
			width: 40px;
		}
	}
}

.sector__0 {
	background: linear-gradient(
		244.34deg,
		rgba(31, 177, 112, 0.97) 37.3%,
		rgba(14, 128, 77, 0.965134) 84.63%,
		#077041 95.96%
	);

	.img-cover {
		background: rgba(111, 207, 151, 0.25);
	}
}
.sector__1 {
	background: linear-gradient(244.34deg, #e64a19, #ff5722);
}

.sector__2 {
	background: linear-gradient(
		253.38deg,
		rgba(221, 157, 60, 0.97) 28.48%,
		rgba(157, 97, 5, 0.965134) 120.46%,
		#9f6307 150.56%
	);
}

.sector__3 {
	background: linear-gradient(
		249.11deg,
		rgba(221, 60, 60, 0.97) 28.88%,
		rgba(187, 52, 52, 0.965134) 78.33%,
		#ac3131 109.33%
	);
}

.sector__4 {
	background: linear-gradient(
		251.7deg,
		rgba(169, 60, 221, 0.97) 20.12%,
		rgba(110, 34, 146, 0.965134) 102.57%,
		#5b157c 151.93%
	);
}

.sector__5 {
	background: linear-gradient(
		254.63deg,
		rgba(60, 76, 221, 0.97) 30.26%,
		rgba(40, 53, 170, 0.965134) 62.25%,
		#091687 102.53%
	);
}

.sector__6 {
	background: linear-gradient(
		253.38deg,
		rgba(221, 205, 60, 0.97) 28.48%,
		rgba(195, 161, 37, 0.968001) 85.36%,
		rgba(157, 97, 5, 0.965134) 129.82%,
		#9f6307 150.56%
	);
}

.sector__7 {
	background: linear-gradient(
		253.38deg,
		rgba(63, 221, 60, 0.97) 28.48%,
		rgba(40, 155, 37, 0.965134) 120.46%,
		#9f6307 150.56%
	);
}
.sector__8 {
	background: linear-gradient(244.34deg, #ff9800, #f57c00);
}
.sector__9 {
	background: linear-gradient(244.34deg, #616161, #757575);
}
.sector__10 {
	background: linear-gradient(244.34deg, #7b1fa2, #9c27b0);
}
.sector__11 {
	background: linear-gradient(244.34deg, #616161, #9e9e9e);
}
.sector__12 {
	background: linear-gradient(244.34deg, #303f9f, #3f51b5);
}
.sector__13 {
	background: linear-gradient(244.34deg, #00796b, #009688);
}
.sector__14 {
	background: linear-gradient(244.34deg, #f57c00, #fbc02d);
}
.sector__15 {
	background: linear-gradient(244.34deg, #1976d2, #2196f3);
}
.sector__16 {
	background: linear-gradient(244.34deg, #0097a7, #00bcd4);
}
.sector__17 {
	background: linear-gradient(244.34deg, #388e3c, #4caf50);
}
.sector__18 {
	background: linear-gradient(244.34deg, #388e3c, #4caf50);
}
.sector__19 {
	background: linear-gradient(244.34deg, #afb42b, #cddc39);
}
.sector__20 {
	background: linear-gradient(244.34deg, #757575, #9e9e9e);
}
.sector__21 {
	background: linear-gradient(244.34deg, #ffa000, #ffc107);
}
.sector__22 {
	background: linear-gradient(244.34deg, #f57c00, #ff9800);
}
.sector__23 {
	background: linear-gradient(244.34deg, #5d4037, #795548);
}
.sector__24 {
	background: linear-gradient(244.34deg, #512da8, #673ab7);
}
.sector__25 {
	background: linear-gradient(244.34deg, #455a64, #607d8b);
}
.sector__26 {
	background: linear-gradient(244.34deg, #2196f3, #03a9f4);
}

.sector__27 {
	background: linear-gradient(244.34deg, #ffc107, #f57c00);
}
.sector__28 {
	background: linear-gradient(244.34deg, #4caf50, #8bc34a);
}
.sector__29 {
	background: linear-gradient(244.34deg, #0288d1, #03a9f4);
}
.sector__29 {
	background: linear-gradient(244.34deg, #c2185b, #e91e63);
}
.sector__30 {
	background: linear-gradient(244.34deg, #00796b, #009688);
}
