@import "assets/styles/scss/variables";

.MuiDrawer-paper {
	width: 250px;
	padding: 20px 0;
	color: $primary-fill;
	background: $white-fill !important;
	#{$meriNight} {
		background-color: $primary-deep !important;
		color: $white-fill;
	}

	a {
		color: $primary-fill !important;
	}

	.logo {
		margin: 0px 0 10px 40px;
	}

	.MuiListItemIcon-root {
		min-width: unset !important;
		margin-right: 20px !important;
	}

	.MuiMenuItem-root,
	.MuiListItem-root {
		border-radius: 0 6px 6px 0;
		min-height: 40px;
		color: $primary-fill;
		font-size: 1.5rem !important;
		font-family: poppins-medium !important;

		.menu__title {
			font-size: 1.5rem;
			font-family: poppins-medium !important;
		}

		&:hover,
		&.active {
			background-color: $primary-fill !important;
			color: $white-fill !important;

			svg {
				path {
					fill: $white-fill;
				}
			}
		}
	}
	.MuiListItem-root {
		width: unset;
		padding: 0 0 0 60px;
		text-decoration: none !important;
		margin: 0 40px 0 auto;
	}
	.MuiMenuItem-root {
		padding: 0 0 0 40px;
		margin: 15px 40px 0 auto;
	}
}

.MuiMenuItem-root {
	font-size: 1.2rem !important;

	&.primary {
		color: &primary-fill;
	}
}
.MuiIconButton-label {
	#{$meriNight} {
		svg {
			path {
				fill: $white-fill;
				fill-opacity: 1;
			}
		}
	}
}

.MuiAppBar-root {
	background-color: $pure-white !important;
	z-index: 10 !important;

	#{$meriNight} {
		background-color: $primary-deep !important;
		color: $white-fill !important;
	}

	.MuiToolbar-root {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 80px;

		svg.dark {
			path {
				fill: $text-fill;
				#{$meriNight} {
					fill: $white-fill;
					fill-opacity: 1;
				}
			}
		}

		.menu__link {
			color: $text-fill;
			text-decoration: none;
			margin-left: 3rem;
			font-family: poppins-medium;
			cursor: pointer;
		}

		.menu__button,
		.menu__link {
			color: $text-fill;
			#{$meriNight} {
				color: $white-fill;
				svg {
					path {
						fill: $white-fill;
						fill-opacity: 1;
					}
				}
				path {
					fill: $white-fill;
					fill-opacity: 1;
				}
			}
		}

		.menu__button {
			margin-left: auto !important;
		}

		@media (max-width: 1024px) {
			padding: 0 30px;
		}

		@media (max-width: 480px) {
			padding: 0 15px;
		}
	}
}

.MuiPaper-elevation4 {
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04) !important;
}

.MuiMenuItem-root {
	font-family: poppins-medium !important;
}

.MuiPaper-rounded {
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1) !important;
}
