@import "./variables";

// Input Field
.form-group {
	font-size: 1em;
	margin-bottom: 2rem;

	.question {
		width: 15px;
		height: 15px;
		margin-left: 10px;
		border-radius: 50%;
		border: 1px solid $gray-fill-main;
		text-align: center;
		display: inline-block;
		font-size: 10px;
		cursor: pointer;
	}

	input {
		padding: 0.8rem 1rem;
		line-height: inherit;
		font-style: normal;
		color: inherit;
		border-radius: 5px;
		width: 100%;

		#{$meriNight} {
			background: unset;
		}

		&::placeholder {
			font: inherit;
			line-height: inherit;
			color: $gray-fill-med;
			#{$meriNight} {
				color: $gray-fill-light-new;
			}
		}

		&:read-only {
			cursor: not-allowed;
		}
		&:disabled {
			background-color: $gray-fill-light;
			#{$meriNight} {
				background: $primary-deep;
			}
		}
	}

	.form-group-label {
		margin-bottom: 0.5rem;
		font-family: poppins-medium;
		color: $gray-fill-main;
		font-weight: 500;
		#{$meriNight} {
			color: $gray-fill-light-new;
		}

		&.required {
			&::after {
				content: "*";
				color: $danger-fill;
				margin-left: 3px;
			}
		}
	}

	.input-wrapper {
		border-radius: 5px;
		border: 1px solid $gray-fill-main;
		#{$meriNight} {
			border: 1px solid $white-fill;
		}

		&.error {
			border: 2px solid $danger-fill;
		}
		&.disabled {
			border: none;
		}
	}

	&.small-form-group {
		input {
			padding: 0.56rem 0.8rem;
			font-size: 1.1rem;
			background: unset !important;
			&::-webkit-calendar-picker-indicator {
				margin-left: 0px !important;
			}
		}
	}

	.password-input-wrapper img {
		cursor: pointer;
	}

	.input-text-error {
		font-size: 0.9em;
		color: $danger-fill;
		font-family: poppins-medium;
	}
}
.show__password svg path {
	fill: $text-fill-main !important;
}

// Checkbox
.custom-check-box {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	width: 100%;
	border-radius: 4px;

	input {
		height: 13px;
		width: 13px;
		padding: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		border-radius: 3px;
		outline: none;
		transition-duration: 0.3s;
		cursor: pointer;
		border: 1px solid $primary-fill;

		&:checked {
			background-color: $primary-fill;
		}
	}

	span {
		font-weight: 600;
		margin-left: 10px;
		margin-top: 2px;
		font-size: 1.2rem;
		color: $gray-fill-main;
	}
}

// Select Field
.form-group-select,
.form-group-select_error,
.form-group-select--is-multi,
.form-group-select--is-multi_error {
	[class*="-control"] {
		margin-top: 0 !important;
		padding: 0 !important;
		background-color: $pure-white;
		border: 1px solid $gray-fill-main !important;
		box-shadow: none !important;
		#{$meriNight} {
			background: unset;
			color: $pure-white;
			border: 1px solid $white-fill !important;
		}
	}

	[class*="__single-value"] {
		color: $text-fill !important;
		#{$meriNight} {
			color: $white-fill !important;
		}
	}

	[class*="-option"] {
		background-color: $pure-white !important ;
		color: unset !important;
		#{$meriNight} {
			background: $primary-deep !important;
		}
	}
	[class*="-option"]:hover {
		cursor: pointer;
		background: $gray-fill-light !important ;
		#{$meriNight} {
			background: $primary-deep !important;
			opacity: 0.5;
		}
	}
	[class*="-container"]:focus {
		border: none !important;
	}

	.form-group-select__indicator,
	.form-group-select--is-multi__indicator {
		padding: 0 10px !important;

		svg {
			height: 20px !important;
			width: 20px !important;

			path {
				fill: $gray-fill-main !important;
			}
		}
	}
	.form-group-select__menu {
		z-index: 9999999999999999 !important;
		background: $white-fill;
		#{$meriNight} {
			background: $primary-deep !important;
		}
	}

	.form-group-select__control--is-disabled {
		background-color: $gray-fill-light;
		#{$meriNight} {
			background: $primary-deep;
		}
	}
}
.form-group-select_error,
.form-group-select--is-multi_error {
	[class*="-control"] {
		border: 2px solid $danger-fill !important;
	}
}

//Otp Input
.otp__input {
	input {
		border: 1px solid $gray-fill-main !important;
		width: 40px !important;
		border-radius: 3px !important;
		margin-right: 10px;
		height: 5rem;
		font-family: poppins-medium;
		font-size: 2rem;

		#{$meriNight} {
			background: unset;
			color: $white-fill;
		}
	}
}

//Date Field
.react-datepicker-wrapper {
	width: 100%;
}
.react-datepicker {
	font-size: 1em;
	.react-datepicker__header {
		padding-top: 0.8em;
	}
	.react-datepicker__month {
		margin: 0.4em 1em;
	}
	.react-datepicker__day-name,
	.react-datepicker__day {
		width: 1.9em;
		line-height: 1.9em;
		margin: 0.166em;
	}
	.react-datepicker__current-month {
		font-size: 1em;
	}
	.react-datepicker__navigation {
		top: 1em;
		line-height: 1.7em;
		border: 0.45em solid transparent;
	}
	.react-datepicker__navigation--previous {
		left: 1em;
	}
	.react-datepicker__navigation--next {
		border-left-color: $white-fill;
		right: 1em;
	}
}

.file__upload {
	margin-bottom: 15px;
	.icon__wrapper {
		height: 40px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		margin-right: 10px;
		border: 1px solid $gray-fill-border;
		border-radius: 50%;
	}

	.progress {
		background: rgba(111, 207, 151, 0.2);
		height: 0.5rem;
		margin: 3px 0;
		border-radius: 4px;
	}
	.form-group__drag-drop-wrapper {
		display: block;
		width: 100%;
		height: 120px;
		padding: 11px 16px;
		background: #f8f9fa;
		text-align: center;
		font-style: normal;
		font-size: 14px;
		border-radius: 4px;
		border: 2px dashed #ccc;
	}

	.form-group__drag-drop-wrapper[data-error="true"] {
		border-color: #dc3545 !important;
	}

	.form-group__drag-drop-wrapper.drag-active {
		border-style: dashed;
		border-width: 2px;
	}

	.form-group__drag-drop__label {
		font-weight: normal;
		font-size: 14px;
	}

	.form-group__drag-drop__label:hover {
		cursor: pointer;
		text-decoration: underline;
	}

	.file-field {
		display: none !important;
	}
}
.MuiOutlinedInput-input {
	padding: 6px 6px 7px 8px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
}
.MuiOutlinedInput-notchedOutline {
	top: -2px !important;
	border: 1px solid $gray-fill-main !important;
}
.MuiFormControl-root {
	min-width: 100% !important;
	display: inline-block;
}
.MuiSelect-icon {
	top: unset !important;
}
.MuiInputBase-root {
	font-size: unset !important;
	#{$meriNight} {
		svg path {
			fill: $gray-fill-light-new;
		}
	}
}
.MuiInputBase-input {
	height: unset !important;
	#{$meriNight} {
		color: $gray-fill-light-new;
	}
}

.MuiList-root {
	#{$meriNight} {
		background: $primary-deep !important;
		color: $gray-fill-light-new !important;
	}
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
	border: 1px solid $gray-fill-main !important;
	background-color: unset !important;
}

.MuiSelect-select {
	&:focus {
		background: unset !important;
	}
}

.borderless {
	.MuiOutlinedInput-input {
		padding: 0 !important;
		font-weight: 600;
		color: $text-fill;
	}

	.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiOutlinedInput-notchedOutline {
		border: none !important;
		background-color: unset !important;
	}
	.MuiSelect-icon {
		top: 1px !important;
	}
	.MuiSelect-select {
		&:focus {
			background-color: unset !important;
		}
	}
}

.small-date {
	width: 80px;
	&.with__filters {
		width: 100px;
	}
}

.no-margin {
	.form-group {
		font-size: 1em;
		margin: 0 !important;
	}
}

.react-tel-input .form-control {
	width: 100%;
	border: none;
	background-color: none;
	#{$meriNight} {
		color: $gray-fill-light-new;
	}
}

.react-tel-input .flag-dropdown {
	border: none;
	background-color: unset;
}

.search__form {
	position: relative;
	max-height: 50px;

	.search__result {
		top: -15px;
		padding: 10px 15px;
		position: relative;
		border-radius: 6px;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3) !important;
		background: $pure-white;
		z-index: 100000000000;

		#{$meriNight} {
			background: $primary-deep !important;
			color: $white-fill;
		}
	}
}

// Small Select

.small-select-container {
	width: 60px;
}

iframe {
	z-index: 2147483647;
	background: rgba(0, 0, 0, 0.5);
	border: 0px none transparent;
	overflow: hidden;
	margin: 0px;
	padding: 0px;
	-webkit-tap-highlight-color: transparent;
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	transition: opacity 0.3s ease 0s;
	visibility: visible;
}
