.social__icons{
	padding: 20px;
	text-align: center;

	.react-share__ShareButton {
		border-radius: 4px !important;
		margin-right: 20px;
		overflow: hidden;
		svg {
			width: 50px !important;
			height: 50px !important;

			rect {
				border-radius: 4px !important;
			}
		}
	}
}