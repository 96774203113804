@import "./variables";

.heading1 {
	font-style: normal;
	font-weight: 600;
	font-family: poppins-medium;
	font-size: 2rem;
	line-height: 40px;
	color: $text-fill-main;
}

.heading2 {
	font-size: 3rem;
	font-style: normal;
	font-weight: 500;
	line-height: 50px;
	letter-spacing: 0px;
	// color: $text-fill;
	@media (max-width: 768px) {
		font-size: 2.5rem;
		line-height: 40px;
	}

	span {
		font-size: 14px;
		font-weight: 600;
	}

	&.small {
		font-size: 2.5rem;
	}
}

.heading3,
.heading4 {
	font-size: 1.4rem;
	font-style: normal;
	font-weight: 600;
	line-height: 27px;
	letter-spacing: 0px;
	color: $text-fill;
	#{$meriNight} {
		color: $gray-fill-light-new;
	}
}

.heading4 {
	font-family: poppins-medium;
}

.text--primary {
	color: $primary-fill !important;
}

.text--danger {
	color: $danger-fill;
}
small {
	&.bit {
		font-size: 0.8em;
	}
}
