@import "assets/styles/scss/variables";

.portfolio {
	position: relative;
	padding: 0 80px;
	margin-top: 40px;
}
.portfolio__header {
	padding: 90px 80px;
	position: relative;
	background: url("../../../../assets/images/images/portBg.png") no-repeat;
	background-color: $primary-btn-grad;
	background-size: cover;
	-webkit-background-size: cover;
	margin-top: -10px;

	&.primary {
		background-color: rgba(200, 26, 47, 0.08);
	}

	.portfolio__header__text {
		position: absolute;
		bottom: 30px;
		font-size: 30px;
		font-weight: 600;
		color: $text-fill;
		#{$meriNight} {
			color: $white-fill;
		}
	}
}

.port {
	.fliud-mg {
		max-width: 50px;
	}
}

@media (max-width: 1024px) {
	.portfolio {
		padding: 30px;
		margin-top: 10px;
	}
}

@media (max-width: 768px) {
	.portfolio__header {
		padding: 70px 30px;
		background: url("../../../../assets/images/images/portBgSm2.png")
			no-repeat;
		background-color: $primary-btn-grad;
		background-size: 120px 70px;
		background-position: bottom right;

		.portfolio__header__text {
			bottom: 20px;
			font-size: 20px;
			font-weight: 600;
		}
	}
}

@media (max-width: 480px) {
	.portfolio {
		padding: 0 14px;
		margin-top: 30px;
	}
	.portfolio__header {
		padding: 70px 14px;
	}
}
