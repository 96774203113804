@import "assets/styles/scss/variables.scss";

.porfolio-report {
	.portfolio__wrap {
		margin-bottom: 20px;
		border-radius: 10px;

		&:hover {
			background: $primary-fill;
			color: $white-fill !important;

			p,
			.primary__light {
				color: $white-fill !important;
			}

			.div__wrapper {
				padding: 7px 0 !important;
			}
		}
		.img-wrap {
			border-radius: 10px;
			// border: 1px solid $gray-fill-med;
			overflow: hidden;
			img {
				width: 100%;
				max-height: 250px;
				background: $white-fill;
			}
		}
	}
}
