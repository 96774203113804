.doughnut svg {
	path,
	circle {
		fill: none;
		stroke-width: 15;
		&.big {
			stroke-width: 50;
		}
		&.medium {
			stroke-width: 25;
		}
		stroke: #ccb64d;
		cursor: pointer;
		&:hover {
			opacity: 0.3;
		}

		&#filled0 {
			stroke: #50556f;
		}
		&#filled1 {
			stroke: #7a64cc;
		}
		&#filled2 {
			stroke: #ff8e78;
		}
		&#filled3 {
			stroke: #c23c3b;
		}
		&#filled4 {
			stroke: #47b2f5;
		}
		&#filled5 {
			stroke: #4e4af1;
		}
		&#filled6 {
			stroke: #08fb7e;
		}
		&#filled7 {
			stroke: #deff73;
		}
		&#filled8 {
			stroke: #6f6d73;
		}
		&#filled9 {
			stroke: #c410f8fc;
		}
		&#filled10 {
			stroke: #046790;
		}
		&#filled11 {
			stroke: #8a8e7c;
		}
		&#filled12 {
			stroke: #2dcc7a;
		}
		&#filled13 {
			stroke: #d59608;
		}
		&.inner-circle {
			fill: #fff0ed !important;
			stroke-width: 2;
			stroke: #ffd8d1;
			&:hover {
				opacity: 1;
			}
		}
	}
	text {
		font-weight: 600;
		&.circle-text {
			fill: #ff8e78;
		}
		&.circle-text-bold {
			font-size: 40px;
		}
	}
}
