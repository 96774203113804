@import "./variables";

.table-responsive {
	-ms-overflow-style: none !important;
	scrollbar-width: none !important;

	&::-webkit-scrollbar {
		display: none;
		// width: 0 !important;
		// background: transparent;
	}

	// &::-webkit-scrollbar-thumb {
	// 	background: #ccc;
	// }
}
.table__wrapper {
	display: flex;
	justify-content: space-between;
	max-height: 550px;
	width: 100%;

	&.full__height {
		max-height: 1000000px;
	}
	&.faint__bg {
		background: $table-bg;
		padding: 5px;
	}
	.thumb-horizontal {
		background-color: red;
		height: 10px !important;
	}

	.table {
		position: relative;
		color: $text-fill !important;
		margin-bottom: 0 !important;
		width: 100%;
		font-size: 1.2rem;

		thead,
		tbody,
		tfoot,
		tr,
		td,
		th {
			border-color: rgba(217, 217, 217, 0.25) !important;
		}

		.table__head {
			background: $gray-fill-light;
			font-weight: 600;
			position: sticky;
			top: 0;
			#{$meriNight} {
				background: $primary-deep;
				color: $gray-fill-light-new;
			}

			th {
				padding: 1rem 2rem !important;
				border-bottom: 0 !important;
			}

			th,
			td {
				vertical-align: middle !important;
			}
		}

		.table__body {
			img {
				max-height: 25px;
			}
		}

		.table__body,
		.table__head {
			td {
				padding: 1rem 2rem !important;
				border-top: 0 !important;
				vertical-align: middle;
				#{$meriNight} {
					color: $gray-fill-light-new;
				}
			}
		}
	}

	.table__index {
		width: 30px;
		border: 1px solid rgba(217, 217, 217, 0.25) !important;
		padding: 0 10px;
		font-weight: 600;
		padding-top: 10px;
	}
}

.table_head_no_fill {
	.table {
		.table__head {
			background: transparent !important;
		}
	}
}
