@import "assets/styles/scss/variables";

.assest__allocation {
	@media (max-width: 768px) {
		&.dash__content {
			height: 100% !important;
		}
	}
	.MuiAccordionSummary-root:nth-last-of-type() {
		border-bottom: 1px solid $gray-fill-border;
	}
	.doughut__wrapper {
		border: 1px solid $gray-fill-border;
		justify-content: space-between;

		@media (max-width: 600px) {
			justify-content: center;
			border: none;
		}
	}
	.shade {
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
	}
}
