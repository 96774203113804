@import "assets/styles/scss/variables";

.profile {
	padding-top: 60px;
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;

	@media (min-width: 768px) {
		.col-md-3 {
			flex: 0 0 auto;
			width: 20%;
		}
	}
	.profileTabs {
		background: #f9fcf9;
		padding: 30px 0 0 0;
		max-height: 100% !important;

		#{$meriNight} {
			background-color: $primary-deep;
		}

		.avatar {
			width: 10em;
			height: 10em;
			border: 0.3px solid $text-fill;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: auto;
			padding: 10px;
			position: relative;
			box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

			.edit {
				position: absolute;
				right: -5px;
				bottom: 20px;
				background: $white-fill;
				z-index: 10;
				border-radius: 50%;
				#{$meriNight} {
					background: $primary-deep;
				}
			}
		}

		.nav-tabs {
			border: none !important;
			.nav-item {
				width: 100%;
			}
			.nav-link {
				width: 100%;
				padding: 20px 20px 20px 70px;
				text-align: left;

				&.active {
					border-left: 5px solid $primary-fill;
					border-bottom: none;
					border-radius: 0;
					padding: 20px 20px 20px 65px;
					background: $primary-btn-grad;
					#{$meriNight} {
						background-color: $primary-full-deep !important;
					}
				}

				@media (max-width: 1024px) {
					padding: 20px 10px 20px 30px;

					&.active {
						padding: 20px 10px 20px 25px;
					}
				}

				@media (max-width: 600px) {
					text-align: center;
					padding: 20px;

					&.active {
						text-align: center;
						padding: 20px;
						border-left: none;
					}
				}
			}
		}
	}
}
