@import "assets/styles/scss/variables.scss";

.research__content {
	max-height: 100%;
	overflow-y: scroll;

	.dividend {
		width: 180px;
		border-radius: 4px;
		padding: 15px 20px 7px 20px;
		font-weight: 600;

		span {
			color: $text-fill-main;
			letter-spacing: 0.5px;

			#{$meriNight} {
				color: $white-fill;
			}
		}
		h1 {
			color: $danger-fill;
		}
		&.dec {
			background: $yellow-light;
			margin-right: 25px;
		}
		&.yield {
			background: $pink-light;
		}
	}
}

.accord_300 {
	max-height: 300px;
}
.accord_400 {
	max-height: 400px;
}

.accord_250 {
	max-height: 250px;
}

.accord_300,
.accord_400,
.accord_250 {
	overflow-y: scroll;
}

.stock-select-text {
	font-size: 14px;
}

.stock-name {
	color: $text-fill;
	font-family: "poppins-medium";
	font-size: 14px;
	font-weight: 700;
}
