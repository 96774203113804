@import "assets/styles/scss/variables";

.modal_stock_list {
	max-height: 300px;
	overflow-y: auto;
	margin: 20px 0;
}

.stock_list_holder {
	width: 100%;
	height: 50px;
	background: $gray-fill-light;
	border-radius: 4px;
	margin-top: -15px;
	padding: 7px;
	img {
		max-width: 40px;
		height: 35px;
		margin: 0 10px;
	}
}
