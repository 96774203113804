$primary-fill: #078950;
$primary-fill-med: #d1e38d;
$primary-fill-light: rgba(209, 227, 141, 0.5);
$primary-deep: #042d1a;
$primary-full-deep: #011e11;
$text-fill-main: #071b2b;
$text-fill: #3e3e3e;
$gray-fill-main: #828282;
$gray-fill-med: #aaaaaa;
$gray-fill-light-new: #d9d9d9;
$gray-fill-border: #e6e3e3;

$gray-fill-light: #f6f6f6;
$success-fill: #219653;
$danger-fill: #c81a2f;
$disabled-fill: #6fcf97;
$white-fill: #ccc;
$pure-white: #fff;
$blue-fill: #305ed2;
$purple-fill: #d230af;
$pink-fill: #fd8962;

$dark-header: #03170e;

$danger-light: rgba(207, 111, 111, 0.15);
$blue-light: rgba(48, 94, 210, 0.15);
$primary-light: rgba(90, 210, 48, 0.15);
$purple-light: rgba(210, 48, 175, 0.15);
$yellow-light: rgba(253, 152, 73, 0.2);
$pink-light: rgba(254, 77, 76, 0.2);

$primary-grad: linear-gradient(
	227deg,
	rgba(31, 177, 112, 0.97) -21.21%,
	#0e804d 53.06%,
	#077041 85.12%
);
$primary-btn-grad: rgba(111, 207, 151, 0.25);
$table-bg: rgba(111, 207, 151, 0.05);

$sidebar-width: 250px;
$meriNight: " .meri__night & ";
