@font-face {
	font-family: "poppins-light";
	src: local("poppins"),
		url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
	font-weight: 400;
}
@font-face {
	font-family: "poppins-medium";
	src: local("poppins"),
		url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
	font-weight: 400;
}

:root {
	--fill-main: #e5e5e5;
	--primary-fill: #078950;
	--primary-deep: #042d1a;
	--primary-full-deep: #011e11;
	--primary-fill-light: rgba(209, 227, 141, 0.5);
	--text-fill: #071b2b;
	--text-light: #d9d9d9;
	--gray-fill: #828282;
	--danger-fill: #c81a2f;
	--danger-light: rgba(200, 26, 47, 0.15);
	--white-fill: #ccc;
	--primary-grad: linear-gradient(
		227deg,
		rgba(31, 177, 112, 0.97) -21.21%,
		#0e804d 53.06%,
		#077041 85.12%
	);
}

html {
	box-sizing: border-box;
	font-size: 56.25%;
	overflow-x: hidden;
}

@media (min-width: 1024px) {
	html {
		font-size: 62.5%;
	}
}

html,
body {
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0;
	position: relative;
}

body {
	position: relative;
	color: var(--text-fill);
	background-color: var(--white-fill);
	font: normal 1.2rem/1.5 "poppins-light", -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
}

body::-webkit-scrollbar {
	display: none !important;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	border: 0;
	outline: 0;
	font: inherit;
	font-size: 100%;
	line-height: inherit;
	vertical-align: baseline;
	margin: 0;
}

a,
a:hover {
	text-decoration: none;
	color: unset;
}

a {
	color: unset;
}

ul {
	padding: 0;
}
li {
	list-style: none;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	font-size: 100%;
	font-family: inherit;
	line-height: 1.15;
}

button,
input {
	overflow: visible;
}

.rounded {
	border-radius: 6px !important;
}

.bg-main {
	background-color: var(--fill-main);
}

.bg--primary {
	background-color: var(--primary-fill);
	color: var(--white-fill);
}
.meri__night .bg--primary {
	background-color: var(--primary-deep);
}

.bg--primary--light {
	background-color: var(--primary-fill-light);
}

.bg--danger--light {
	background-color: var(--danger-light);
}
.bg-white {
	color: var(--text-fill) !important;
}
.meri__night .bg-white {
	background-color: var(--primary-full-deep) !important;
	color: var(--white-fill) !important;
}

.text--primary {
	color: var(--primary-fill) !important;
}

.text--gray {
	color: var(--gray-fill);
}
.meri__night .text--gray {
	color: var(--text-light);
}

.flex__center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex__between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex__end {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.grid__center {
	display: grid;
	place-content: center;
}

.pointer {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:checked {
	-webkit-transition-delay: 9999s;
	transition-delay: 9999s;
}
.rel {
	position: relative;
}

.abs {
	position: absolute;
}
