.settings__header {
	position: relative;
	padding-bottom: 20px;
}

.settings__content {
	position: relative;
	padding: 10px 80px;
	margin-top: 60px;

	@media (max-width: 480px) {
		padding: 0 14px;
	}
}

.paragraph {
	font-size: 1.8rem;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 0px;

	@media (max-width: 768px) {
		font-size: 1.5rem;
	}
}

.paragraph2 {
	font-size: 1.7rem;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 0px;

	@media (max-width: 768px) {
		font-size: 1.5rem;
	}
}

.icon--dark-toggle {
	margin-top: -3px;
	margin-left: 2px;
}
