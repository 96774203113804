@import "assets/styles/scss/variables";

.dash__content {
	position: relative;
	padding: 30px 80px;
	margin-top: 60px;
	overflow: hidden;
	@media (max-width: 1024px) {
		padding: 30px;
	}

	@media (max-width: 768px) {
		// height: calc(100vh - 175px);
		overflow-y: scroll;
	}

	@media (max-width: 480px) {
		padding: 0 14px;
	}

	&.full__screen {
		@media (max-width: 768px) {
			height: 100%;
			overflow-y: unset;
		}
	}

	.left__view {
		.balance__box {
			text-align: center;
			padding: 15px;
			background-color: $primary-fill;
			background-image: url("../../../assets/images/icons/dash-flower.svg"),
				url("../../../assets/images/icons/dash-flower2.svg");
			background-position: top center, bottom right;
			background-repeat: no-repeat;
			color: $white-fill;
			border-radius: 10px;
			margin-top: 10px;
			margin-bottom: 20px;

			.icons {
				width: 30px;
				height: 30px;
				cursor: pointer;
			}

			@media (max-width: 768px) {
				.coin {
					width: 60px;
				}
				.icons {
					width: 25px;
					height: 25px;
				}
			}
		}

		.alloc,
		.dash__market {
			border: 1px solid $gray-fill-border;
			padding: 10px 15px;
			margin-right: 30px;
			border-radius: 6px;
			#{$meriNight} {
				// border: unset;
			}
		}

		.alloc {
			min-width: 290px;
			display: flex;
			width: auto;
			#{$meriNight} {
				border: 1px solid $gray-fill-border;
				// box-shadow: 0px 0px 20px 0 rgba(255, 255, 255, 0.1);
			}

			@media (max-width: 768px) {
				min-width: 270px;
			}
		}

		.dash__market {
			min-width: 200px;
			display: inline-block;
		}

		.latest__news {
			margin-top: 60px;

			&.dash {
				div {
					max-height: unset !important;
				}
				.div__wrapper {
					min-width: 700px;
					margin-right: 30px;
					@media (max-width: 480px) {
						min-width: 300px;
						max-width: 400px;
					}
				}
			}
		}
		.dash__watchlist,
		.dash__port {
			width: 80vw;
			min-width: 80vw;
			margin-bottom: 50px;
		}
		.dash__watchlist {
			margin-right: 30px;
		}
		.dash__port {
			margin-right: 10px;
		}
	}

	.right__view {
		right: 70px;
		position: fixed;
		@media (max-width: 1130px) {
			right: 30px;
			padding-left: 40px;
		}

		.dash__watchlist {
			max-height: 260px;
			padding: 5px;
			margin-bottom: 20px;
		}
		.dash__port {
			padding: 5px 5px 15px 5px;
		}
	}
	.MuiMenuItem-root {
		min-height: unset !important;
	}
}

.new__image__wrapper {
	height: 220px;
	overflow: hidden;

	img {
		height: 100%;
		width: 100%;

		@media (max-width: 480px) {
			height: 110px;
		}
	}
}

.dash-portfolio {
	padding: 10px 20px;
	display: flex;
	justify-content: space-around;
	cursor: pointer;
}

.bottom__button {
	position: sticky;
	bottom: 0;
	width: 100%;
	padding: 15px;
	z-index: 2;
}
.accord {
	max-height: 180px;
	overflow-y: scroll;
}
.news__footer {
	position: absolute;
	bottom: 15px;
	width: 90%;

	&.dash {
		bottom: 10px;
	}
	@media (max-width: 768px) {
		position: relative;
		padding-top: 30px;
		bottom: 0;
	}
}
