@import "assets/styles/scss/variables";

.auth {
	.auth__right__view {
		background-color: $primary-fill;
		background-image: url("../../assets/images/images/onboarding2.png");
		background-repeat: no-repeat;
		margin-top: -80px;
	}
	.form-group {
		max-width: unset !important;
		margin-right: auto;
		margin-left: auto;
	}
	.allocation {
		display: block !important;
		right: 0 !important;
		left: unset !important;
		width: 200px;
		bottom: -40px !important;
	}
	.doughnut {
		width: 30px !important;
		margin-left: 20px;
	}
}
