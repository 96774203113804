@import "assets/styles/scss/variables.scss";

.notifications {
	padding-top: 60px;
	height: 100vh;
	overflow-x: hidden;

	.notif__sidebar {
		background: #f9fcf9;
		padding: 30px;
		max-height: 100%;

		#{$meriNight} {
			background: $primary-deep;
		}

		#notifTabContent {
			height: calc(100vh - 235px);
			overflow-y: scroll;
		}
	}

	.MuiAccordionSummary-root {
		border-bottom: 1px solid $gray-fill-border;
	}
}
.notif__content {
	max-height: 100%;
	overflow-y: scroll;
	.dash__content {
		padding: 0;
		margin-top: 20px;
	}
}
.notif__list {
	border-bottom: 1px solid $gray-fill-border;
	padding: 10px 0;
	cursor: pointer;
}

.notification__details {
	height: calc(100vh - 60px);
	position: relative;
}

.pretext {
	white-space: pre-line;
}
