@import "assets/styles/scss/variables";

.modal {
	.modal-dialog {
		min-height: 100vh;
		display: flex;
		align-items: center;
	}
	.modal-content {
		border-radius: 0.4em;
		padding: 0;
		border: 0;
	}

	.primary__header {
		text-align: center;
		padding: 15px;
		background-color: $primary-fill;
		background-image: url("../../assets/images/icons/flower.svg"),
			url("../../assets/images/icons/flower2.svg");
		background-position: top right, bottom left;
		background-repeat: no-repeat;
		color: $white-fill;
		p {
			color: $white-fill;
		}
	}
	&.meri__night {
		.modal-content {
			background-color: $primary-full-deep;
			color: $white-fill;
		}
		.heading1 {
			color: $white-fill;
		}
	}
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 600px !important;

		&.med__modal {
			max-width: 800px !important;
		}
	}

	// .modal-xl {
	// 	max-width: 900px !important;
	// }
}
