@import "assets/styles/scss/variables";

.range__dot {
	border-radius: 50%;
	border: 3px solid $gray-fill-border;
	max-width: 17px;
	max-height: 17px;
	min-width: 17px;
	min-height: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.small__dot {
		border-radius: 50%;
		background: $gray-fill-border;
		width: 5px;
		height: 5px;
	}

	&.primary {
		border: 3px solid $primary-fill;
		.small__dot {
			background: $primary-fill;
		}
	}
}
.dot-line {
	background: $gray-fill-border;
	width: 100%;
	height: 3px;
	margin: 0 3px;
}
